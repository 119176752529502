<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="老师分类">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择老师分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in teacherTypeData"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用状态">
          <el-select
            v-model="queryForm.state"
            placeholder="请选择启用状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加老师</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="teacherList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="老师科目" prop="type" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 1">日语老师</div>
            <div v-if="scope.row.type == 2">韩语老师</div>
            <div v-if="scope.row.type == 3">法语老师</div>
          </template>
        </el-table-column>
        <el-table-column label="老师名称" prop="name" align="center"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
                <img :src="scope.row.avatar" alt="" v-if="scope.row.avatar != ''">
                <img src="https://static.qiaxueedu.com/qiaxueedu/avatar.jpg" alt="" v-else>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="老师电话" prop="phone" align="center"></el-table-column>
        <el-table-column label="启用状态" prop="state" align="center">
             <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.state == 1"
            >
              启用
            </el-tag>
            <el-tag type="danger" v-else 
              >禁用
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time" align="center"></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <!-- <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加/编辑老师 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="老师分类" label-width="100px">
            <el-select
              v-model="addForm.type"
              placeholder="请选择老师分类"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in teacherTypeData"
                :key="item.key"
              ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item el-form-item label="启用状态" label-width="100px">
            <el-select
              v-model="addForm.state"
              placeholder="请选择启用状态"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in displayArr"
                :key="item.key"
              ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="老师电话" label-width="100px" v-if="isAdd == 'add'">
          <el-select style="width:50%" filterable remote  clearable :remote-method="searchUser" v-loadmore="loadMore" v-model="addForm.user_id"  placeholder="请输入老师电话">
            <el-option :label="`${item.phone}——${item.nickname}`" :value="item.id" v-for="item in userArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="老师名字" label-width="100px">
            <el-input
            type="text"
            placeholder="请输入老师名字"
            v-model="addForm.name"
            style="width:50%"
          />
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
import {
  TeacherList,
  addTeacherList,
  editTeacherList,
  delTeacherList
} from "@/api/onetooneTeacher.js";
import {SelectUser} from "@/api/order";
export default {
  data() {
    return {
      queryForm: {
        type: null,
        state: 1,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      teacherTypeData: [
        { key: 1, value: "日语老师"},
        { key: 2, value: "韩语老师"},
        { key: 3, value: "法语老师"}
      ],
      teacherList: [],
      displayArr: [
        { key: -1, value: "禁用" },
        { key: 1, value: "启用" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        user_id: "",
        type: 3,
        name: "",
        state: 1,
      },
      addtitle: "",
      isAdd: "add",
      userArr: [],
      userTotal: 0,
    };
  },
  watch: {
    "addForm.name": {
      handler(newVal) {
        if (newVal == "") {
          let data = {
            page: 1,
            limit: 10,
          };
          SelectUser(data).then((res) => {
            this.userTotal = res.data.total;
            this.userArr = res.data.data;
          });
          return;
        }
      },
      deep: true,
    },
  },
  created() {
    this.getTeacherList();
    this.getUserListData();
  },
  methods: {
    //   获取老师列表
    getTeacherList() {
      TeacherList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.teacherList = res.data.data;
      });
    },
    // 获取用户列表
    getUserListData() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        this.userTotal = res.data.total;
        this.userArr = res.data.data;
      });
    },
    // 用户搜索
    searchUser(query) {
      let data = {
        page: 1,
        limit: 10,
        phone: query,
      };
      SelectUser(data).then((res) => {
        this.userArr = res.data.data;
      });
    },
    // 选择用户触底
    loadMore() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        if (this.userTotal < Math.ceil(this.userArr.length / 10) + 1) {
          return;
        }
        this.userArr = this.userArr.concat(res.data.data);
      });
    },

    // 删除老师
    // async removeTask(id) {
    //   const result = await this.$confirm("是否要删除该老师?", "删除提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).catch((err) => err);
    //   if (result !== "confirm") return this.$message.info("已经取消删除");
    //   delTeacherList({ id: id }).then((res) => {
    //     if (res.code !== 1) {
    //       this.$message.error(res.msg);
    //       return;
    //     }
    //     this.$message.success("删除老师成功");
    //     this.getTeacherList();
    //   });
    // },
    // 老师状态
    // editState(id, index) {
    //   let data = {
    //     id: id,
    //     display: index,
    //   };
    //   TeacherDisplay(data).then((res) => {
    //     if (res.code !== 1) {
    //       this.$message.error(res.msg);
    //       return;
    //     }
    //     this.$message.success("修改老师状态成功");
    //     this.getTeacherList();
    //   });
    // },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加老师";
      this.addDialog = true;
    },

    // 添加老师
    addNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择老师分类");
        return;
      } else if (this.addForm.user_id == "") {
        this.$message.error("请输入老师电话");
        return;
      } else if (this.addForm.name == "") {
        this.$message.error("请输入老师名字");
        return;
      }

      addTeacherList(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加老师成功");
        this.addDialog = false;
        this.getTeacherList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑老师";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择老师分类");
        return;
      } else if (this.addForm.user_id == "") {
        this.$message.error("请输入老师电话");
        return;
      } else if (this.addForm.state == "") {
        this.$message.error("请选择状态");
        return;
      } else if (this.addForm.name == "") {
        this.$message.error("请输入老师名字");
        return;
      }

      editTeacherList(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改老师成功");
        this.addDialog = false;
        this.getTeacherList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        user_id: "",
        type: 3,
        name: "",
      };
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getTeacherList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getTeacherList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getTeacherList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
