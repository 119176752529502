import { render, staticRenderFns } from "./onetooneTeacher.vue?vue&type=template&id=7ef8cc08&scoped=true"
import script from "./onetooneTeacher.vue?vue&type=script&lang=js"
export * from "./onetooneTeacher.vue?vue&type=script&lang=js"
import style0 from "./onetooneTeacher.vue?vue&type=style&index=0&id=7ef8cc08&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef8cc08",
  null
  
)

export default component.exports